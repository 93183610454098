<template>
    <v-container>
        <v-row no-gutters>
            <v-col>
                <div>
                    <v-skeleton-loader type="table" v-if="api.isLoading" ref="skeleton">

                    </v-skeleton-loader>
                    <v-data-table
                    :search="searchCompanies"
                    :items="this.filteredCompanies"
                    :headers="this.companiesRenewalHeader"
                    class="elevation-1"
                    dense 
                    v-if="this.companiesRenewal!=null"
                    >  

                    <template v-slot:item.company_name="{item}">
                        <router-link :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                            {{ item.company_name }}
                        </router-link>
                    </template>

                    <template v-slot:top>
                        <v-toolbar width="auto" height="auto" color="py-3" flat>
                            <v-row no-gutters>
                                <v-col class="mt-3 mx-3">
                                    <v-row class="mb-3">
                                        <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                            Renewal
                                        </v-toolbar-title>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-select @change="filterRenewalStatus" outlined dense clearable
                                            v-model="renewalStatusFiltered"
                                            :items="renewalStatusOption"
                                            label="Filter renewal status...">
    
                                            </v-select>
                                            
                                        </v-col>
                                        <v-col>
                                            <v-text-field v-model="searchCompanies" dense outlined label="Search...">

                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                    </template>


                    <template v-slot:item.Index="{item}">
                        {{ companiesRenewal.indexOf(item)+1 }}
                    </template>


                    </v-data-table>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default{
    data:()=>({
        renewalStatusOption:[
            'Pending',
            'Unreachable',
            'Confirm',
            'Confirm - Pending HRDC',
            'Rejected',
        ],
        searchCompanies:null,
        renewalStatusFiltered:null,
        companiesRenewal : null,
        filteredCompanies:null,
        companiesRenewalHeader :[
            {
                text:'#',
                value:'Index',
                align:'start',
                sortable:false,
            },
            {
                text:'Name',
                value:'company_name',
            },
            {
                text:'Status',
                value:'renewal_status',
            },
            {
                text:'Reported By',
                value:'user_name',
            },
            {
                text:'Reported Date',
                value:'created_at',
            },
            
        ],
        api : {
                isLoading:false,
                isError:false,
                error:null,
                url:null,
            },
        
    }),
    created(){
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=="getRenewalCompanies"){
                this.companiesRenewal = resp.data;
                this.filteredCompanies = this.$_.clone(this.companiesRenewal);
            }
            this.api.isLoading = false;

        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            let fetchREnewalCompaniesApi = this.fetchRenewalCompanies();
            this.$api.fetch(fetchREnewalCompaniesApi);
        },
        fetchRenewalCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.url = process.env.VUE_APP_SERVER_API+'/renewal/status';
            tempApi.method = "GET";
            return tempApi;
        },
        filterRenewalStatus(){
            let tempCompanies = this.$_.clone(this.companiesRenewal);
            let filter = this.$_.clone(this.renewalStatusFiltered);

            let tempFilteredCompanies = tempCompanies.filter(function(item){
                if(filter==="Unreachable"){
                    return (item.renewal_status==="Unreachable");
                }
                if(filter==="Pending"){
                    return (item.renewal_status==="Pending");
                }
                if(filter==="Rejected"){
                    return (item.renewal_status==="Rejected");

                }
                if(filter==="Confirm"){
                    return (item.renewal_status==="Confirm");
                }
                if(filter==="Confirm - Pending HRDC"){
                    return (item.renewal_status==="Confirm - Pending HRDC");
                }
                if(filter===null){
                    return true;
                }
            })
            this.filteredCompanies = tempFilteredCompanies;
        },
    }
}
</script>